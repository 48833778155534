import React from 'react'
import FAQs from '../FAQs'


function FrequentlyAskedQuestions() {
  return (
    <div style={{padding:'60px 0'}} className='dark-container'>
      

<div className="FAQ-wrapper">
                <FAQs/>    
                </div>
</div>
   
  )
}

export default FrequentlyAskedQuestions